import './styles.scss';

import Rails from "@rails/ujs"

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
// require("channels");

// import('popper.js');
// import('bootstrap');
import "popper.js";
import "bootstrap";

import "@fancyapps/fancybox/dist/jquery.fancybox.min.js"
import "jarallax/dist/jarallax.min.js"
import "flickity-fade/flickity-fade.js"
import "flickity-as-nav-for/as-nav-for.js"
import "highlightjs/highlight.pack.min.js"
import "smooth-scroll/dist/smooth-scroll.min.js"
import "list.js/dist/list.min.js"
import "simplebar/dist/simplebar.min.js"

// import "flickity/dist/flickity.pkgd.min.js"

import "./js/card.js"
import "./js/collapse.js"
import "./js/countdown.js"
import "./js/dropdown.js"
import "./js/flickity.js"
import "./js/form.js"
import "./js/highlight.js"
import "./js/img-comp.js"
import "./js/list.js"
import "./js/navbar.js"
import "./js/popover.js"
import "./js/rating.js"
import "./js/simplebar.js"
import "./js/popover.js"
import "./js/smooth-scroll.js"
import "./js/tooltip.js"
import "./js/vote.js"

import "./vue_apps/search.js";

Rails.start()

$(window).on('load', function() {

});

$(document).on('ready', function() {

});
