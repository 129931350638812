//
// popover.js
//

'use strict';

import 'popper.js';
import 'bootstrap';

$(function() {
  $('[data-toggle="popover"]').popover();
});
