import Vue from "vue/dist/vue.esm";
import Search from "../vue_components/search.vue";

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: '#searchComponent',
    components: {
      Search
    }
  });
});
