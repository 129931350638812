<template>
  <div class="search">
    <div class="modal-body">
      <form>
<!--        <div class="form-group">-->
<!--          <label class="sr-only" for="modalSearchCategories">Categories:</label>-->
<!--          <select class="custom-select" id="modalSearchCategories">-->
<!--            <option selected>All Categories</option>-->
<!--            <option>Women</option>-->
<!--            <option>Men</option>-->
<!--            <option>Kids</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="input-group input-group-merge">
          <input class="form-control" type="search" placeholder="Поиск" v-model="word">
          <div class="input-group-append">
            <button class="btn btn-outline-border" type="submit">
              <i class="fe fe-search"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

  <!-- Body: Results (add `.d-none` to disable it) -->
  <div v-show="empty_results" class="modal-body border-top font-size-sm">
    <!-- Heading -->
    <div v-if="itemsData && itemsData.length > 0">
      <h5>Галерея</h5>
    </div>
    <!-- Items -->
    <div class="row align-items-center position-relative my-5 pb-5"
      v-for="item in itemsData"
    >
      <div class="col-3">
        <!-- Image -->
        <!--        <img class="card-img-top card-img-front" style="width: 50px;" :src=item.image_url_small />-->
        <div class="bg-as-image-cover" :style="{ 'background-image': 'url(' + item.image_url_small + ')' }" style="height: 50px; width: 50px; background-color: rgba(13, 84, 201, .1);" />
      </div>
      <div class="col position-static">
        <!-- Text -->
        <p class="mb-0 font-weight-bold">
          <a class="stretched-link text-body" :href=itemPath(item)>{{ item.title }}</a>
          <br>
          <span class="text-muted">{{ item.price }} ₽</span>
        </p>
      </div>
    </div>
    <div v-if="itemsData && itemsData.length === 5">
      <a class="btn btn-link pt-0 mt-n5 px-0 text-reset" :href="'/arts?q[title_or_keywords_cont_any]=' + word">
        Смотреть все <i class="fe fe-arrow-right ml-2"></i>
      </a>
    </div>

    <!-- Heading -->
    <div v-if="artistsData && artistsData.length > 0">
      <h5>Художники</h5>
    </div>
    <!-- Items -->
    <div class="row align-items-center position-relative my-5"
         v-for="artist in artistsData"
    >
      <div class="col-3">
        <!-- Image -->
<!--        <img class="card-img-top card-img-front" style="width: 50px;" :src=artist.avatar_url />-->
        <div class="bg-as-image-cover" :style="{ 'background-image': 'url(' + artist.avatar_url + ')' }" style="height: 50px; width: 50px; background-color: rgba(13, 84, 201, .1);" />
      </div>
      <div class="col position-static">
        <!-- Text -->
        <p class="mb-0 font-weight-bold">
          <a class="stretched-link text-body" :href="'/artists/' + artist.id">{{ [artist.name, artist.surname].join(" ") }}</a>
        </p>
      </div>
    </div>
  </div>

  <div v-show="!empty_results && empty_word" class="modal-body">
    <p class="mb-3 font-size-sm text-center">
      Введите название картины или имя художника
    </p>
  </div>

  <div v-show="!empty_results && !empty_word" class="modal-body">
    <p class="mb-3 font-size-sm text-center">
      Ничего не найдено
    </p>
    <p class="mb-0 font-size-sm text-center">
      😞
    </p>
  </div>
</div>
</template>

<script>
import { isEmpty, isNull, debounce } from "lodash";
import qs from "qs";
const axios = require("axios");

export default {
  name: "GlobalSearch",
  data() {
    return {
      word: "",
      artistsData: [],
      itemsData: [],
    };
  },
  computed: {
    empty_results() {
      return (this.itemsData.length + this.artistsData.length > 0);
    },
    empty_word() {
      return isNull(this.word) || this.word.length === 0;
    },
    results() {
      return this.itemsData.concat(this.artistsData);
    },
  },
  mounted() {
    this.initDebounce();
  },
  methods: {
    resultsReset() {
      this.artistsData = [];
      this.itemsData = [];
      this.usersData = [];
    },
    itemPath(item) {
      return "/items/" + item.id
    },
    async getArtists() {
      let vm = this;
      axios
        .get(`/api/front/artists`, {
          params: {
            q: {
              name_or_surname_cont_any: vm.word.split(" ")
            }
          },
          paramsSerializer: requestParams =>
            qs.stringify(requestParams, {
              arrayFormat: "brackets",
              encode: false
            })
        })
        .then(function(resp) {
          vm.artistsData = resp.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getItems() {
      let vm = this;
      axios
          .get(`/api/front/items`, {
            params: {
              q: {
                title_or_keywords_cont_any: vm.word.split(" ")
              }
            },
            paramsSerializer: requestParams =>
                qs.stringify(requestParams, {
                  arrayFormat: "brackets",
                  encode: false
                })
          })
          .then(function(resp) {
            vm.itemsData = resp.data;
          })
          .catch(function(error) {
            console.log(error);
          });
    },
    resultsOpen() {
      $(".search-dropdown").dropdown("show");
      // this.$refs.word.focus();
    },
    resultsClose() {
      $(".search-dropdown").dropdown("hide");
      // this.$refs.word.focus();
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.getArtists();
        this.getItems();
      }, 150);
    }
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value) || value.length < 1) {
        this.resultsReset();
      } else {
        this.debouncedSearch();
      }
    },
    results(value) {
      isEmpty(value) ? this.resultsClose() : this.resultsOpen();
    }
  }
};
</script>
